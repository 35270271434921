<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav
          class="
            navbar navbar-expand-md navbar-dark
            px-0
            pr-sm-3
            navbar-offcanvas
          "
        >
          <a @click="back" class="btn p-0 mr-md-4 text-light h5 mb-0">
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Penjualan</span>
          </span>
          <Menu>
            <ul class="navbar-nav w-100 pl-3 pl-md-0">
              <li class="nav-item mt-1 mb-n1 active">
                <router-link
                  to="/jual/penjualan"
                  class="nav-link"
                  title="Pengaturan umum"
                  >Penjualan</router-link
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <a
                  href=""
                  class="nav-link"
                  title="pekan ini"
                  @click.prevent="filter('minggu')"
                  >Pekan ini</a
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <a
                  href=""
                  class="nav-link"
                  title="Bulan ini"
                  @click.prevent="filter('bulan')"
                  >Bulan ini</a
                >
              </li>
              <li class="nav-item mt-1 mb-n1">
                <a
                  href=""
                  class="nav-link"
                  title="Tahun ini"
                  @click.prevent="filter('tahun')"
                  >Tahun ini</a
                >
              </li>
            </ul>
          </Menu>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div class="row mt-4 mb-5">
            <div class="col-12">
              <div class="row mt-3 mb-5">
                <div class="col-12 mb-3">
                  <a href="#" class="badge badge-pill" :class="state.filter == ''? 'badge-primary':'badge-light'"
                    @click.prevent="filter('')"
                    >Hari Ini</a
                  >
                  <a href="#" class="badge badge-pill" :class="state.filter == 'kemarin'? 'badge-primary':'badge-light'" @click.prevent="filter('kemarin')">Kemarin</a>
                  <a href="#" class="badge badge-pill" :class="state.filter == 'minggu'? 'badge-primary':'badge-light'" @click.prevent="filter('minggu')">Seminggu</a>
                  <a href="#" class="badge badge-pill" :class="state.filter == 'bulan'? 'badge-primary':'badge-light'" @click.prevent="filter('bulan')">Sebulan</a>
                  <a href="#" class="badge badge-pill" :class="state.filter == 'tahun'? 'badge-primary':'badge-light'" @click.prevent="filter('tahun')">Setahun</a>
                </div>
                <div class="col-12 col-sm-6">
                  <h5 class="mb-2">Penjualan</h5>
                  <div class="table-responsive">
                    <table class="table table-striped table-sm border bg-white">
                      <tbody class="text-nowrap">
                        <tr>
                          <td class="pl-3">Jumlah Transaksi</td>
                          <td>{{ state.data.jumlah_transaksi }}</td>
                        </tr>
                        <tr>
                          <td class="pl-3">Jumlah Barang</td>
                          <td>{{ state.data.jumlah_barang }}</td>
                        </tr>
                        <tr>
                          <td class="pl-3">Nilai</td>
                          <td>Rp {{ changeMoney(state.data.nilai) }}</td>
                        </tr>
                        <tr>
                          <td class="pl-3">Modal</td>
                          <td>Rp {{ changeMoney(state.data.modal) }}</td>
                        </tr>
                        <tr>
                          <td class="pl-3">Margin</td>
                          <td>Rp {{ changeMoney(state.data.margin) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <canvas id="XmyChart"></canvas>
                  <!-- unt transaksi seminggu, sebulan, setahun -->
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import Menu from "../../../../components/menu/Menu.vue";
import { reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import { useVueSweetAlert2 } from "../../../../useVueSweetAlert2.js";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      loading: false,
      data: {},
      filter: "",
    });
    const loadData = async () => {
      state.loading = true;
      await axios.get("/api/seller/penjualan?filter="+state.filter).then((res) => {
        if (res.data.success) {
            state.data = res.data.data;
            state.loading = false;
        }
      });
    };
    const filter = (dt) => {
        state.filter = dt;
        loadData();
    }
    onMounted(() => {
      loadData();
      store.dispatch("error/setDefault");
    });
    const batal = () => {
      $swal
        .fire({
          text:
            "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/pesanan-group" });
          }
        });
    };
    const back = () => {
      window.history.back();
    };
    const changeMoney = (dt) => {
      let cur = Math.ceil(Number(dt));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];
      return ret;
    };
    return {
      state,
      batal,
      back,
      changeMoney,
      filter,
    };
  },
  components: {
    Menu,
  },
};
</script>
